.manage-access-wrapper {
  .page-title-wrapper {
    border: 2px solid #9600ff4f;
    padding: 1%;
    margin: 1%;
    .page-title-text {
      font-size: 20px;
      font-weight: 600;
      color: #9600ffbf;
    }
  }
  .add-buttons {
    padding-top: 1.5%;
    .add-user-btn {
      margin-right: 1%;
      .user-icon {
        height: 15px;
        width: 15px;
        margin-bottom: 1vh;
        margin-right: 5px;
      }
    }
    .add-super-admin-btn {
      margin-right: 1%;
      .admin-icon {
        height: 15px;
        width: 15px;
        margin-bottom: 1vh;
        margin-right: 5px;
      }
    }
    .download-guide-btn {
      margin-right: 1%;
      .download-icon {
        svg {
          margin-bottom: 1vh;
        }
      }
    }
  }
}
.manage-access-container {
  .tick-icon {
    cursor: pointer;
  }
  .grey-tick {
    filter: grayscale(120%);
  }
  .manage-access-table {
    padding-top: 1.5%;
    margin: 1%;
    .ant-table-column-title {
      font-size: 13px;
      font-weight: 200;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-left: 10px;
    }

    .ant-table-thead {
      .ant-table-column-sorter {
        color: #ffffff;
      }
    }

    .ant-table-tbody {
      font-size: 12px;

      .ant-table-row .ant-table-cell {
        padding-left: 0.15%;
      }
    }

    .ant-table {
      color: #000;
      border-radius: 10px;

      table {
        border-spacing: 2px;
      }
    }

    .ant-table-thead > tr > th {
      font-size: 13px;
      font-weight: 200;
      padding-left: 10px;
      color: #0000a0;
      background: #a6db00 !important;
    }

    .ant-table-tbody > tr > td {
      border: none;
      background: #f0f0f0;
    }
  }
}
