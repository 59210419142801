.d-left-section-filters {
  max-height: 100%;

  .selectWrapper {
    padding: 2% 5%;

    .select-input {
      width: 173px;
    }

    .fieldWrapper {
      .ant-select-multiple .ant-select-selection-item-content {
        font-weight: 600;
      }

      .oos-toggle {
        padding-right: 10px;
        font-size: 11px;
      }
    }
  }
  .logoWrapper {
    margin-left: 20%;
  }

  .heading {
    font-style: "Mars Centra";
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #393939 !important;
    position: relative;
    width: 58px;
    height: 28px;
    top: 5px;
    left: 10px;
  }

  .label-block {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    width: 12vw;
  }

  .ant-form-item-label {
    padding: 0 !important;

    label {
      font-size: 12px;
      line-height: 24px !important;
      color: #000000;
      opacity: 0.7;
    }
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  .ant-collapse {
    > .ant-collapse-item {
      > .ant-collapse-header {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px !important;
        background: #f9f9f9;
        padding: 8px !important;
        display: flex;

        div {
          order: 2;
          margin-left: auto;
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .clear-all-filters {
    font-style: "Mars Centra";

    cursor: pointer;
    margin-top: 15px;
  }

  .apply-btn {
    margin-top: 15px;
    margin-right: 10px;
  }

  .site-info {
    margin-top: 1px;
    margin-left: 5px;
    color: #9600ff;
    font-size: 15px;
    font-weight: bold;
  }
}

.apply-note-wrapper {
  line-height: 1vw;
  text-align: center;
}

.apply-note {
  padding: 10px;
  font-size: 10.5px;
  color: #8bb703;
}
