.faq-wrapper {
  background-color: #f5f5f5 !important;
  padding: 1% 2% 2% 2%;
  .radio-btn-row {
    padding-bottom: 1%;
  }
  .ant-tabs-tab {
    font-size: 16px;
  }
  .definitions-tab {
    .faq-text-black {
      color: black;
      font-size: 18px;
    }
    .data-joins {
      padding: 30px 10px;
      margin-left: 350px;
      .title {
        margin-left: 200px;
        color: black;
        font-size: 18px;
      }
    }
  }
  .calculations-tab {
    .faq-text-black {
      font-size: 14px;
      color: black;
    }
    .faq-text-blue {
      font-size: 14px;
      color: #0000a0;
    }
    .calculation-table {
      width: 30%;
      margin-left: 35%;
    }
  }
  .adhering-color {
    color: #0000ff;
  }
  .nonadhering-color {
    color: #bf40bf;
  }
  .partially-adhering-color {
    color: #6495ed;
  }
  .not-in-use-color {
    color: #979797;
  }
  .no-data-color {
    color: #282c35;
  }
}
