.trendingview-wrapper {
  background-color: #f5f5f5 !important;
  padding: 0% 0.5%;
  .title_row {
    text-align: center;
    .title-text {
      background-color: #0000a0;
      font-size: 18px;
      margin-bottom: 0.3em;
      margin-top: 0.5em;
      color: #ffffff;
      font-family: "mars-centra-bold", Arial, sans-serif;
    }
  }
  .radio {
    padding-bottom: 0.03%;
    .radio-buttons-wrapper {
      margin-top: 0%;
    }
    .switch-col {
      margin: 0.75% 0% 0.5% 0%;
      .charts-table-switch {
        display: inline-flex;
        background: #b040ffad;
        color: white;
        border-radius: 4px;
        padding: 1px;
        .text {
          font-size: 14px;
          padding: 0.1px 5px;
          font-style: "Mars Centra";
        }
        .switchtotable {
          background-color: #0000a076;
        }
      }
    }
    .last_updated_text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: #000000;
      padding: 0.5% 0% 0% 1.5%;
      font-style: "Mars Centra";
      .alert-refresh-date {
        color: red;
      }
    }
  }

  .filters-row {
    background-color: #ffffff;
    padding: 0.1%;
    .dimension-filters {
      display: inline-flex;
      .aggregate-info {
        margin-top: 1%;
        margin-left: 0.5%;
        width: 16%;
        .label {
          color: #000000;
        }
      }
      .ou {
        width: 28%;
        .level-radio-selection {
          margin-left: 5.5%;
          margin-top: 3.5%;
          span.ant-radio + * {
            font-size: 12px;
            color: #000000;
          }
        }
      }
      .time {
        width: 20%;
        .time-radio-selection {
          margin-left: 1%;
          margin-top: 4.5%;
          span.ant-radio + * {
            font-size: 12px;
            color: #000000;
          }
        }
      }
      .filter {
        width: 30%;
        display: inline-flex;
        .selected-level-label {
          margin: 3%;
          font-size: 14px;
          color: #000000;
        }
        .selected-level-filter {
          margin: 3% 0.2% 3% 3%;
          width: 200px;
        }
      }
      .apply-reset-buttons {
        display: inline-flex;
        .clear-all-filters {
          font-style: "Mars Centra";
          cursor: pointer;
          margin: 9.5% 12% 5% 15%;
        }
      }
    }
  }
  .charts-section {
    padding: 0.5% 1% 0% 1%;
    .charts-heading {
      font-size: 16px;
      color: #0000a0;
      padding: 0% 5% 0% 1%;
      font-family: "mars-centra-bold", Arial, sans-serif;
      .no-data-note {
        font-size: 11px;
        color: red;
        padding: 0% 5% 0% 1%;
        font-family: "mars-centra-book", Arial, sans-serif !important;
      }
    }
  }
}
.charts-container {
  // .g2-tooltip {
  //   font-size: 11px !important;
  //   max-height: 300px !important;
  //   overflow-y: scroll !important;
  //   position: absolute;
  // }
  .trend-chart {
    background-color: #ffffff;
    padding: 1%;
    margin: 0.5% 0% 1.5% 0%;
    height: 180px;
    .chart-title {
      margin: 0.5% 1.5%;
      color: #000000;
      font-family: "mars-centra-bold", Arial, sans-serif;
      font-size: 14px;
    }
    .focus-mode-icon-1 {
      cursor: pointer;
      margin-left: 45vh;
    }
    .focus-mode-icon-2 {
      cursor: pointer;
      margin-left: 41vh;
    }
    .focus-mode-icon-3 {
      cursor: pointer;
      margin-left: 32vh;
    }
    .focus-mode-icon-4 {
      cursor: pointer;
      margin-left: 36vh;
    }
    .pqm-focus-mode-icon-3 {
      cursor: pointer;
      margin-left: 31vh;
    }
    .pqm-focus-mode-icon-4 {
      cursor: pointer;
      margin-left: 34vh;
    }
  }

  .left-chart {
    padding: 1%;
    margin: 0.5% 2% 1% 7.2%;
  }

  .upper-chart {
    padding: 1%;
    margin: 0.1% 2% 2% 28%;
  }

  .bottom-left-chart {
    padding: 1%;
    margin: 0.5% 2% 1% 7.2%;
  }

  .bottom-right-chart {
    padding: 1%;
    margin: 0.5% 2% 1% 1%;
  }
}
.change-summary-container {
  background-color: #ffffff;
  .first-col {
    display: inline-flex;
    .legend-info {
      padding: 1% 0% 0.5% 1%;
      .note-text {
        font-size: 11px;
        color: #8c2dcf;
      }
    }
    .reset-search-btn {
      padding: 0.5% 7% 0% 0%;
    }
  }
  .change-summary-table {
    .ant-table-column-title {
      font-size: 12px;
      font-weight: 200;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-left: 10px;
    }

    .ant-table-thead {
      .ant-table-column-sorter {
        color: #ffffff;
      }
    }

    .ant-table-tbody {
      font-size: 12px;

      .ant-table-row .ant-table-cell {
        padding-left: 0.1%;
      }
    }

    .ant-table {
      color: #000;
      border-radius: 10px;

      table {
        border-spacing: 2px;
      }
    }

    .ant-table-thead > tr > th {
      font-size: 15px;
      font-weight: 100;
      color: #0000a0;
      background: #a6db00 !important;
    }

    .ant-table-tbody > tr > td {
      border: none;
      background: #f0f0f0;
    }
  }
}
.focus-mode-container {
  height: 100%;
  width: 100%;
  .focus-mode-chart {
    background-color: #ffffff;
    padding: 0% 1%;
    margin: 1.5% 0.5%;
    height: 83vh;
    // position: relative !important;
    .chart-title {
      margin: 0.5% 1.5%;
      color: #000000;
      font-family: "mars-centra-bold", Arial, sans-serif;
      font-size: 14px;
    }
  }
  // .g2-tooltip {
  //   font-size: 11px !important;
  //   max-height: 300px !important;
  //   overflow-y: scroll !important;
  //   position: absolute !important;
  //   opacity: 0 !important;
  //   // transition: opacity 1s !important;
  // }
  // .focus-mode-chart:hover .g2-tooltip {
  //   opacity: 1 !important;
  // }
}
