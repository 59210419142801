.input-tools-wrapper {
  .page-note-wrapper {
    margin: 4%;
    padding: 0% 10%;
    .note-text {
      text-align: center;
      font-size: 11px;
    }
  }
  .radio-btns {
    margin-top: 0.5%;
    margin-left: 0.5%;
  }
  .page-title-wrapper {
    border: 2px solid #9600ff4f;
    padding: 1%;
    margin: 1%;
    .page-title-text {
      font-size: 20px;
      font-weight: 600;
      color: #9600ffbf;
      .info-icon {
        margin-top: 4px;
        width: 12px;
        height: 12px;
        padding-left: 1vw;
        padding-right: 2vw;

        .info-image {
          color: #9600ff;
          font-size: 17px;
          font-weight: bold;
        }
      }
    }
  }
  .main-content {
    margin: 1%;
    .table-wrapper {
      .threshold-table {
        max-width: 60%;
        margin: 0 auto;
      }
    }
  }
  .actions-content {
    margin: 1%;
  }

  .button-wrapper {
    .cancel-btn {
      margin-right: 1%;
    }
  }
  .ant-input {
    padding: 2px;

    border-color: #d9d9d9;
    border-radius: 6px;
  }
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        .modal-confirm-text {
          font-size: 12px;
          padding: 1%;
        }
      }
    }
  }
}
