.combined-circles {
  ul {
    text-align: justify;
    position: relative;
    padding-block: 95px;
    padding-left: 0;
  }

  li {
    position: relative;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 0.6rem;
    }
  }

  .first-circle {
    position: absolute;
    width: 5em;
    height: 5em;
    border: 5px solid #a6db00;
    border-radius: 50%;
    top: -185%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: black;
      font-size: 10px;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 15px;
      padding: 3px;
    }
  }

  .second-circle {
    position: absolute;
    width: 5em;
    height: 5em;
    border: 5px solid #0000a0;
    border-radius: 50%;
    top: -185%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: black;
      font-size: 10px;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 15px;
      padding: 3px;
    }
  }

  .third-circle {
    position: absolute;
    width: 5em;
    height: 5em;
    border: 5px solid #30d5c8;
    border-radius: 50%;
    top: -185%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: black;
      font-size: 10px;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 15px;
      padding: 3px;
    }
  }

  .fourth-circle {
    position: absolute;
    width: 5em;
    height: 5em;
    border: 5px solid #ffe900;
    border-radius: 50%;
    top: -185%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: black;
      font-size: 10px;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 15px;
      padding: 2px;
    }
  }

  .fifth-circle {
    position: absolute;
    width: 5em;
    height: 5em;
    border: 5px solid #9600ff;
    border-radius: 50%;
    top: -185%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: black;
      font-size: 9px;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 15px;
      padding: 1px;
    }
  }

  li:nth-child(1):after {
    content: "";
    border: 24px solid #a6db00;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    margin-top: -2px;
    left: 65px;
  }

  li:nth-child(2):after {
    content: "";
    border: 24px solid #0000a0;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    margin-top: -2px;
    left: 65px;
  }

  li:nth-child(3):after {
    content: "";
    border: 24px solid #30d5c8;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    margin-top: -2px;
    left: 65px;
  }

  li:nth-child(4):after {
    content: "";
    border: 24px solid #ffe900;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    margin-top: -2px;
    left: 65px;
  }

  li:nth-child(1)::before {
    content: "";
    width: 5px;
    height: 15%;
    position: absolute;
    top: -15%;
    transform: translate(-50%, -50%);
    margin-top: -2px;
    left: 50%;
    background: #a6db00;
  }

  li:nth-child(2)::before {
    content: "";
    width: 5px;
    height: 15%;
    position: absolute;
    top: -15%;
    transform: translate(-50%, -50%);
    margin-top: -2px;
    left: 50%;
    background: #0000a0;
  }

  li:nth-child(3)::before {
    content: "";
    width: 5px;
    height: 15%;
    position: absolute;
    top: -15%;
    transform: translate(-50%, -50%);
    margin-top: -2px;
    left: 50%;
    background: #30d5c8;
  }

  li:nth-child(4)::before {
    content: "";
    width: 5px;
    height: 15%;
    position: absolute;
    top: -15%;
    transform: translate(-50%, -50%);
    margin-top: -2px;
    left: 50%;
    background: #ffe900;
  }

  li:nth-child(5)::before {
    content: "";
    width: 5px;
    height: 15%;
    position: absolute;
    top: -15%;
    transform: translate(-50%, -50%);
    margin-top: -2px;
    left: 50%;
    background: #9600ff;
  }

  .active:after {
    border-color: lightblue;
  }

  ul:after {
    content: "";
    display: inline-block;
    width: 100%;
  }

  li {
    width: 6em;
    height: 6em;
    text-align: center;
    line-height: 2em;
    border-radius: 50%;
    background: white;
    margin: 0 5px;
    display: inline-block;
    color: white;
  }

  li:nth-child(1) {
    border: 10px solid #a6db00;
  }

  li:nth-child(2) {
    border: 10px solid #0000a0;
  }

  li:nth-child(3) {
    border: 10px solid #30d5c8;
  }

  li:nth-child(4) {
    border: 10px solid #ffe900;
  }

  li:nth-child(5) {
    border: 10px solid #9600ff;
  }
}
