.corporate_assessment {
  width: 100%;

  .table_wrapper {
    padding-right: 0.5%;
  }

  .sidebar {
    padding: 0.5% 0.5% 0%;

    .ant-layout-sider-children {
      background-color: #fff;
    }
  }
  .ant-layout-sider {
    background: none !important;
  }

  .lagging-units-section,
  .leading-units-section {
    margin-top: 1.5%;
    background: #fff;

    .ant-pagination-options {
      display: none;
    }

    .heading {
      font-style: "Mars Centra";
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      color: #393939 !important;
      position: relative;
      width: 58px;
      height: 28px;
      top: 5px;
      left: 10px;
    }

    .info-icon {
      margin-top: 4px;
      width: 12px;
      height: 12px;
      padding-left: 1vw;
      padding-right: 2vw;

      .info-image {
        color: #9600ff;
        font-size: 17px;
        font-weight: bold;
      }
    }

    .content {
      padding: 2%;

      .radiobtn {
        margin: 2%;

        label {
          font-size: 13px;
        }
      }

      .display-heading {
        margin: 2%;
      }

      .display-data {
        margin: 2%;
      }
      .admin-custom-table {
        .ant-table-column-title {
          font-size: 13px;
          font-weight: 200;
        }

        .ant-table-thead {
          .ant-table-column-sorter {
            color: #ffffff;
          }
        }

        .ant-table-tbody {
          font-size: 12px;

          .ant-table-row .ant-table-cell {
            padding-left: 6%;
          }
        }

        .ant-table {
          color: #000;
          border-radius: 10px;

          table {
            border-spacing: 2px;
          }
        }

        .ant-table-thead > tr > th {
          font-size: 15px;
          font-weight: 100;
          color: #0000a0;
          background: #a6db00 !important;
          // Green #a6db00
          // Blue #0000a0
        }

        .ant-table-tbody > tr > td {
          border: none;
          background: #f0f0f0;
        }

        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td,
        .ant-table tfoot > tr > th,
        .ant-table tfoot > tr > td {
          padding: 6px 4px;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .custom-table {
        .ant-table-column-title {
          font-size: 13px;
          font-weight: 200;
        }

        .ant-table-thead {
          .ant-table-column-sorter {
            color: #ffffff;
          }
        }

        .ant-table-tbody {
          font-size: 12px;

          .ant-table-row .ant-table-cell {
            padding-left: 6%;
          }
        }

        .ant-table {
          color: #000;
          border-radius: 10px;

          table {
            border-spacing: 2px;
          }
        }

        .ant-table-thead > tr > th {
          font-size: 15px;
          font-weight: 100;
          color: #0000a0;
          background: #a6db00 !important;
          // Green #a6db00
          // Blue #0000a0
        }

        .ant-table-tbody > tr > td {
          border: none;
          background: #f0f0f0;
        }

        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td,
        .ant-table tfoot > tr > th,
        .ant-table tfoot > tr > td {
          padding: 6px 4px;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .dot {
        height: 12px;
        width: 12px;

        border-radius: 50%;
        display: inline-block;
        margin-top: 1.5%;
        position: absolute;
        margin-left: -17%;
      }

      .cell-data {
        margin-left: 15%;
      }

      .no-data-cell {
        margin-left: -4%;
      }
    }
  }

  .footer {
    background: #fff;
    margin: 0% 0.5% 0% 0.5%;

    .buttonWrapper {
      .view-assessment-button {
        float: right;
        margin: 2%;
        cursor: pointer;
      }
    }
  }
}

.world-map {
  width: 1201px;
  height: 774px;
  margin: 166px 455px 140px 264px;
}

.world-map-width {
  .map-wrapper {
    padding: 0.5% 0.5% 0% 0%;
  }

  .searchWrapper {
    padding: 1.5% 0% 0% 1.5%;

    .search-input {
      display: none;
      width: 165px;
    }
  }
  .radio-buttons-wrapper {
    margin-top: 0.5%;
  }

  .last_updated_text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: #000000;
    padding: 0.5% 0% 0% 1.5%;
    font-style: "Mars Centra";
    .alert-refresh-date {
      color: red;
    }
  }

  .mqm-header-contents {
    padding: 0%;
    padding-right: 0.5%;
    background: #fff;

    .mqm-title {
      font-style: "Mars Centra";
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin: 0.5% 2%;
      color: #393939 !important;
    }

    .mqm-text {
      font-style: "Mars Centra";
      font-size: 12px;
      padding: 1%;
      color: #393939 !important;
    }

    .corporate-percent-compliance {
      font-style: "Mars Centra";
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      color: #393939 !important;
      margin-top: 10px;
    }

    .corporate-compliance-indicators {
      display: inline-flex;
      margin-left: 5px;
      cursor: pointer;

      .legend-info-btn {
        margin-left: 2.5px;
        font-size: 11px;
        font-weight: 600;
        margin-top: 5px;
      }

      .corporate-compliance-indicator-text {
        font-style: "Mars Centra";
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        color: #393939;
        margin-top: 10px;
      }

      .text-dark {
        margin-left: 5px;
        color: #393939;
      }

      .text-faded {
        margin-left: 5px;
        color: #d8d8d8;
      }

      .corporate-compliance-indicator-circles {
        border-radius: 50%;
        margin-top: 12px;
        width: 10px;
        height: 10px;
      }
      .corporate-compliance-indicator-square {
        margin-top: 12px;
        width: 10px;
        height: 10px;
      }
    }

    .red {
      background-color: #df4941;
    }

    .grey {
      background-color: #d8d8d8;
    }

    .orange {
      background-color: #ef981d;
      margin-left: 10px;
    }

    .yellow {
      background-color: #ffcd1c;
      margin-left: 10px;
    }

    .green {
      background-color: #c2da61;
      margin-left: 10px;
    }

    .olive {
      background-color: #6b9962;
      margin-left: 10px;
    }

    .black {
      background-color: #000000;
      margin-left: 10px;
    }

    .left-spaced-grey {
      margin-left: 10px;
      background-color: #d8d8d8;
    }
  }

  .switch-to-one-site-data {
    font-style: "Mars Centra";
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #393939 !important;
    display: inline-flex;
  }

  .main-content {
    margin-top: 5px;
    background: #fff;

    .tableIndicators {
      padding: 0% 0.5% 0.5% 0.5%;

      .percent-compliance {
        font-style: "Mars Centra";
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #393939 !important;
        margin-top: 10px;
      }

      .compliance-indicators {
        display: inline-flex;
        margin-left: 5px;

        .compliance-indicator-text {
          font-style: "Mars Centra";
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #393939;
          margin-top: 10px;
        }

        .compliance-indicator-circles {
          border-radius: 50%;
          margin-top: 12px;
          width: 10px;
          height: 10px;
        }
      }

      .download-button {
        border-radius: 6px;
        border-color: #0000a0;
        border-width: 2px;

        .download-icon {
          margin-top: 1px;
          color: #0000a0;
          width: 16px;
          height: 16px;

          padding-right: 2vw;
        }

        .download-text {
          color: #000000;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}
.page-title {
  font-style: "Mars Centra";
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  margin: 0% 1% 0.5% 0.2%;
  color: #0000a0 !important;
}

.table-header {
  font-size: 11px;
  padding: 0;
}

.legendInfo {
  .info-container {
    font-size: 14px;
    color: black;
  }

  .info-table {
    width: 30%;
    margin-left: 35%;
  }
}
