.site-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0px 2px 12px rgba(65, 65, 65, 0.16);
  padding: 4px 10px;
  background-color: #a6db00;
  top: 0;
  position: fixed;
  width: 100%;
  height: 55px;

  .header-left {
    display: flex;
    align-items: center;
    width: 50%;
    .home-icon {
      margin-top: 2px;
      margin-right: 8px;
      cursor: pointer;
      position: relative;
    }

    .header-divider {
      width: 1px;
      height: 58px;
      display: flex;
      background: #000;
      margin: 0 20px;
    }

    .select-competitor {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      position: relative;

      .logo-img {
        width: 40px;
        height: 40px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: contain;
          position: absolute;
        }
      }

      .brand-name {
        font-size: 16px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0 16px 0 8px;
      }

      .header-select {
        top: calc(100% + 1px);
        position: absolute;
        background: #fff;
        left: 0;

        &.ant-select-open {
          &::after {
            content: "";
            height: 20px;
            width: 100%;
            background: #fff;
            top: 100%;
            left: 0;
            position: absolute;
          }
        }
      }
    }

    .page-name {
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0 0 0 24px;
        padding: 0;

        > li {
          font-size: 16px;
          line-height: 22px;
          font-weight: 700;
          color: #0000a0;

          + li::before {
            content: ">";
            margin: 0 5px;
          }

          a {
            color: #7d7d7d;
            position: relative;

            &::after {
              content: "";
              width: 100%;
              height: 1px;
              display: block;
              background: #7d7d7d;
              left: 0;
              bottom: 0;
              position: absolute;
            }
          }
        }
      }
    }

    .quality-logo {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 20px;
    }
  }

  .site-logo {
    display: flex;
    align-items: center;
    position: relative;

    .brand-select {
      padding-right: 15px;
      vertical-align: center;
    }

    a {
      margin: 0 12px 0 0;
    }
  }

  .header-right {
    width: 60%;
    max-width: 800px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .menu {
      list-style: none;
      margin: 0px 10px 0px 0px;
      padding: 0;
      display: flex;
      align-items: center;

      .menu-item {
        font-weight: 400;
        font-size: 14.5px;
        line-height: 22px;
        color: #000000;
        transition: all 0.3s ease-in;
        cursor: pointer;
        margin: 0 25px 0 0;
        white-space: nowrap;

        &.active {
          font-weight: 700;
          color: #0000a0;
          position: relative;

          &::after {
            content: "";
            width: 100%;
            height: 4px;
            display: block;
            border-radius: 4px;
            background-color: #0000a0;
            position: absolute;
            top: calc(100% + 8px);
          }
        }
      }
    }
    .user-image {
      cursor: pointer;
      margin-top: -24px;
      margin-left: -28px;
      position: fixed;

      &.active {
        border: 2px solid #0000a0;
      }
    }

    .questionmark-outlined {
      margin-left: -67.81px;
      display: inline-block;
      margin-right: 2px;
      margin-top: -2px;
      position: fixed;
      cursor: pointer;
    }
  }

  .profile-link {
    display: inline-block;
  }
}
