.trackboard-top {
  margin: 0 0 32px;
}
.ps-top {
  background: #ffffff;
  padding: 16px 14px;
  border-radius: 12px;
}
.performance-filter {
  label {
    font-size: 12px;
    line-height: 16px;
    color: #9a9a9a;
    margin: 0 0 4px;
  }
}
.card-style {
  h4 {
    font-size: 12px !important;
    color: #575555 !important;
    font-weight: normal !important;
  }
  .data-value {
    font-size: 25px !important;
    line-height: 40px !important;
  }
}
.chart-btn {
  border-color: #dadaff;
  margin-right: 10px;
  width: 80px;
  text-align: center;
  cursor: pointer;
}
.data-card {
  background: #fff;
  border-radius: 12px;
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);

  .data-head {
    margin: 0 0 16px;
    padding: 0 16px;
    position: relative;

    h4 {
      color: #000;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }
    .d-check {
      top: 0;
      left: 0;
      position: absolute;
    }
    .d-more {
      top: 0;
      right: 0;
      position: absolute;
    }
  }
  .data-value {
    font-size: 47px;
    line-height: 74px;
    color: #0000a0;
  }
  .comparison-wrap {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    .c-box {
      border: 1.5px solid #dcdcdc;
      border-radius: 14px;
      padding: 14px;
      min-width: 160px;
      margin: 0 3px;

      .c-box-title {
        color: #393939;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 6px;
      }
      .c-box-value {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        &.green {
          color: #00c853;
        }
        &.red {
          color: #ec3225;
        }
      }
    }
  }
}

.section-title {
  padding: 0 16px;
  position: relative;
  margin: 0 0 24px;

  h4 {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    text-align: center;
  }
  .d-more {
    top: 0;
    right: 0;
    position: absolute;
  }
}
.chart-wrap {
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  height: 100%;
}
.table-chart {
  padding: 20px 10px 10px;
  background: #ffffff;
  border-radius: 12px;
}
.year-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;

  .ant-radio-group {
    margin-left: auto;
  }
}
.sku-wrap {
  padding: 16px;
  border-radius: 12px;
  background: #ffffff;

  .sku-chart-left {
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    height: 100%;

    .circle-chart {
      padding: 16px 24px 24px;
      margin-right: 10px;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
    }
  }
  .sku-table-wrap {
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    height: 100%;
  }
}
.nutro-skus {
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  .nutro-skus-filter {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background: rgba(234, 234, 234, 0.6);

    > div {
      margin-right: 24px;

      .view-label {
        font-size: 12px;
        line-height: 16px;
        color: #393939;
        display: inline-block;
        margin-right: 8px;
      }
    }
    .ant-radio-group-small {
      .ant-radio-button-wrapper {
        margin-right: 4px;
        padding: 2px 16px;
        border-radius: 6px;
        border-left-width: 1px;
        font-size: 12px;
        line-height: 14px;
        height: 20px;

        &:not(:first-child)::before {
          content: none;
        }
      }
    }
  }
}
.scatter-info {
  margin: 10px 10px 10px 0;
  background: #f2f2f2;
  padding: 0 10px;
  text-align: center;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > li {
      margin: 4px 6px;

      &:first-child {
        flex: 1 0 100%;
        text-align: center;
      }

      label {
        font-weight: 600;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}
