.login-layout {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url("../../images/login-layout-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -66px;
}
.login-wrapper {
  max-width: 800px;
  min-height: 500px;
  background-color: #fff;
  border-radius: 6px;
  min-width: 800px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-between;
  &.simple {
    min-width: 320px;
    min-height: auto;
    padding: 40px 0 20px;
    .login-form-wrap {
      width: 100%;
    }
    .login-bg {
      display: none;
    }
  }
}
.login-form-wrap {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  .welcome-text {
    text-align: center;
  }
}
.login-bg {
  width: 40%;
  background-image: url("./../../images/login-bg.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}
.not-found-wrap {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  .welcome-text {
    text-align: center;
  }
}
.not-found-bg {
  width: 70%;
  background-image: url("./../../images/access.png");
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}
