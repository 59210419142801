.admin-layout {
  padding-top: 1%;
  .trigger {
    padding: 0.5%;
    color: white;
    float: right;
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .admin-sider {
    height: 90vh;
    background-color: #deb0ffd4; //#bfbfbf;
    .ant-menu {
      background: transparent;
      color: black;
    }
    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
      color: #fff;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
      .ant-menu-item-selected {
      background-color: #9600ffbf;
    }
  }
  .admin-content {
    background-color: white;
  }
  .site-layout {
    height: 90vh;
  }
}
