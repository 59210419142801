.material_assessment {
  width: 100%;

  .content_wrapper {
    padding-right: 0.5%;

    .title_row {
      .radio-buttons-wrapper {
        margin-top: 0.5%;
      }
      .last_updated_text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        color: #000000;
        padding: 0.5% 0% 0% 2%;
        font-style: "Mars Centra";
        .alert-refresh-date {
          color: red;
        }
      }
    }
    .title-download-btn {
      .page-title {
        font-style: "Mars Centra";
        font-size: 24px;
        font-weight: 700;
        line-height: 33.6px;
        margin: 0% 1% 0.5% 0.1%;
        color: #0000a0 !important;
      }
    }
    .main-content {
      background: #fff;

      .first-row {
        display: inline-flex;
        .reset-search-button {
          padding: 0.1% 0.5% 0.5% 1%;
        }
        .tableIndicators {
          padding: 0% 0.5% 0.5% 0.5%;
          margin-right: 5px;

          .percent-compliance {
            font-style: "Mars Centra";
            font-size: 11px;
            line-height: 16px;
            font-weight: 400;
            color: #393939 !important;
            margin-top: 10px;
          }

          .compliance-indicators {
            display: inline-flex;
            margin-left: 5px;
            .legend-info-btn {
              font-size: 11px;
              font-weight: 600;
              margin-top: 5px;
            }
            .compliance-indicator-text {
              font-style: "Mars Centra";
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              margin-top: 10px;
              margin-left: 5px;
            }
            .text-dark {
              margin-left: 5px;
              color: #393939;
            }
            .text-faded {
              margin-left: 5px;
              color: #d8d8d8;
            }
            .compliance-indicator-circles {
              border-radius: 50%;
              margin-top: 12px;
              width: 10px;
              height: 10px;
              margin-left: 25px;
            }

            .info-icon {
              margin-top: 4px;
              width: 12px;
              height: 12px;
              padding-left: 1vw;
              padding-right: 2vw;
            }
          }
        }
      }
    }
    .searchWrapper {
      padding: 0% 2% 0% 1%;

      .view-assessment-button {
        font-size: 11px;
      }

      .search-input {
        padding-left: 2%;
        width: 31%;
      }
    }

    .material-table-section {
      background: #fff;
      .ant-table {
        font-size: 13px;
      }

      .ant-table-container table > thead > tr {
        border-top-left-radius: 2px;
        padding: 0px;
      }

      .ant-table-container table > thead > tr > th {
        background: #fff;
        font-weight: 600;
      }

      .ant-table.ant-table-small .ant-table-title,
      .ant-table.ant-table-small .ant-table-footer,
      .ant-table.ant-table-small .ant-table-thead > tr > th,
      .ant-table.ant-table-small .ant-table-tbody > tr > td,
      .ant-table.ant-table-small tfoot > tr > th,
      .ant-table.ant-table-small tfoot > tr > td {
        padding: 4px;
      }
    }

    .download-btn {
      margin: 0% 0.5% 0.5% 0.5%;
    }
  }

  .sidebar {
    padding: 0.5% 0.5% 0%;

    .ant-layout-sider-children {
      background-color: #fff;
    }
  }
  .ant-layout-sider {
    background: none !important;
  }
  .green {
    color: green;
  }
  .red {
    color: #b80302;
  }
  .black {
    color: #525252;
  }
  .overall_compliance {
    border-radius: 50%;
    margin-top: 12px;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
  .adhering {
    background-color: #0000ff;
  }
  .nonadhering {
    background-color: #bf40bf;
  }
  .partially-adhering {
    background-color: #6495ed;
  }
  .not-in-use {
    background-color: #979797;
  }
  .no-data {
    background-color: #282c35;
  }
  .grey {
    background-color: #d8d8d8;
  }
  .grey-color {
    color: #d8d8d8;
  }
  .adhering-color {
    color: #0000ff;
  }
  .nonadhering-color {
    color: #bf40bf;
  }
  .partially-adhering-color {
    color: #6495ed;
  }
  .not-in-use-color {
    color: #979797;
  }
  .no-data-color {
    color: #282c35;
  }
  .col-info-icon {
    margin-top: 1px;
    margin-left: 2px;
    color: #9600ff;
    font-size: 15px;
    font-weight: bold;
  }
}
.material-popover-content {
  // width: 40vw;

  .title {
    color: black;
    text-align: center;
  }
}
