.corporate-map {
  background: #fff;
  .marker-tooltip-content {
    width: max-content;
    .wrapper {
      overflow: "hidden";
      .list {
        display: flex;
      }
    }

    .title {
      padding: 0.5%;
    }
  }
}
