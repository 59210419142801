.home-wrapper {
  background-color: #f5f5f5 !important;
  padding: 0.1% 0.5%;
  .process-adherence-display {
    padding: 10px 2.5px;
    .mqm-percent {
      .block {
        display: inline-flex;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: large;
          height: 5em;
          width: 12.5em;
          border: 1.5px solid #0000a0;
          background-color: #ffffff;
        }
        .percent {
          margin-left: 0.2em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: large;
          font-weight: bolder;
          color: #ffffff;
          height: 5em;
          width: 10em;
          background-color: #ffc300;
        }
      }
    }
    .pqm-percent {
      .block {
        display: inline-flex;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: large;
          height: 5em;
          width: 12.5em;
          border: 1.5px solid #0000a0;
          background-color: #ffffff;
        }
        .percent {
          margin-left: 0.2em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: large;
          font-weight: bolder;
          color: #ffffff;
          height: 5em;
          width: 10em;
          background-color: #0000a0;
        }
      }
    }
  }
  .info-tag {
    color: #ffffff;
    font-size: 16px;
    background-color: #0000a0;
  }
  .main-contents {
    .left-contents {
      margin-top: 2em;
      .first-module {
        .item {
          display: inline-flex;
          .button {
            text-align: center;
            height: 6em;
            width: 10vw;
            border-radius: 8px;
            background-color: #0000a0;
            margin: 5px 30px 5px 3px;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
          }
          .raws-description {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background-color: #ffffff;
            border: 2px solid #0000a0;
            width: 51.8vw;
            height: 6em;
            margin-top: 4.5px;
            .title {
              font-size: 16px;
              font-weight: bold;
              margin-left: 5px;
            }
            .definition {
              font-size: 14px;
              font-weight: 200;
              padding-left: 5px;
            }
          }
        }
      }
      .second-module {
        .item {
          display: inline-flex;
          .button {
            text-align: center;
            height: 6em;
            width: 10vw;
            border-radius: 8px;
            background-color: #0000a0;
            margin: 15px 30px 5px 3px;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
          }
          .packs-description {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background-color: #ffffff;
            border: 2px solid #0000a0;
            width: 51.8vw;
            height: 6em;
            margin-top: 14.5px;
            .title {
              font-size: 16px;
              font-weight: bold;
              margin-left: 5px;
            }
            .definition {
              font-size: 14px;
              font-weight: 200;
              padding-left: 5px;
            }
          }
        }
      }
      .third-module {
        .item {
          display: inline-flex;
          .button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 6em;
            width: 10vw;
            border-radius: 8px;
            background-color: gray;
            margin: 15px 30px 5px 3.5px;
            font-size: 14px;
            color: #ffffff;
            cursor: not-allowed;
          }
          .emqm-description {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background-color: #ffffff;
            border: 1px solid #0000a0;
            width: 51.8vw;
            height: 6em;
            margin-top: 15px;
            .title {
              font-size: 16px;
              font-weight: bold;
              margin-left: 5px;
              color: gray;
            }
            .definition {
              font-size: 14px;
              font-weight: 200;
              padding-left: 5px;
              color: gray;
            }
          }
        }
      }
      .fourth-module {
        .item {
          display: inline-flex;
          .button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 6em;
            width: 10vw;
            border-radius: 8px;
            background-color: gray;
            margin: 15px 30px 5px 3.5px;
            font-size: 14px;
            color: #ffffff;
            cursor: not-allowed;
          }
          .dqm-description {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background-color: #ffffff;
            border: 1px solid #0000a0;
            width: 51.8vw;
            height: 6em;
            margin-top: 15px;
            .title {
              font-size: 16px;
              font-weight: bold;
              margin-left: 5px;
              color: gray;
            }
            .definition {
              font-size: 14px;
              font-weight: 200;
              padding-left: 5px;
              color: gray;
            }
          }
        }
      }
    }
    .alerts-block {
      display: block;
      padding: 0.1% 0.6% 0.1% 0.2%;
      margin-top: 0.05em;
      .alerts-title {
        font-size: 18px;
        color: #0000a0;
        padding: 0% 5% 1% 0.8%;
        font-family: "mars-centra-bold", Arial, sans-serif;
      }
      .alerts-section {
        padding: 0.5% 1%;
        background-color: #ffffff;
        border: 2px solid #0000a0;
        border-radius: 8px;
        width: 34vw;
        height: 28.7em;
        margin: 0.5% 1%;
        display: list-item;
        list-style: none;
        overflow-y: scroll;
        .switch-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;
          .title {
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            margin: 0% 1%;
          }
          .switch-state {
            background-color: #0000a0 !important;
          }
        }
        .raws-alerts {
          margin: 2% 0%;
          .alert-note {
            display: block;
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            color: #000000;
            margin: 0% 0% 1% 1%;
            border-bottom: 1px solid #f2f2f2;
          }
        }
      }
    }
  }
  .footer {
    text-align: right;
    padding: 0.1% 0.6% 0.1% 0.5%;

    .contact-card {
      .title {
        font-size: 16px;
        font-weight: bolder;
        color: #0000a0;
      }

      .feedback-text {
        margin-left: 18px;
        font-size: 16px;
        font-weight: 400;
      }

      .icon-feedback {
        margin-top: -5px;
        margin-left: 10px;
        width: 20px;
        height: 20px;
        alt: "feedback";
        cursor: pointer;
      }

      .email-text {
        margin-left: 25px;
        font-size: 16px;
        font-weight: 400;
        color: #0000a0;
      }

      .email-id {
        margin-left: 5px;
        font-size: 16px;
        font-weight: bolder;
        color: #9600ff;
      }
    }
  }
}
