.request-page-wrapper {
  width: 100%;
  .loaderWrapper {
    margin: 0px auto;
  }
  .request-page-content {
    background-color: #fff;
    padding: 1%;
    .welcome-text {
      text-align: center;
      margin-top: 1.5em;
    }
    .image-wrapper {
      text-align: center;
      img {
        width: 20%;
        height: 20%;
      }
    }
    .pending-image-wrapper {
      text-align: center;
      img {
        width: 40%;
        height: 20%;
      }
    }
    .rejected-image-wrapper {
      text-align: center;
      img {
        width: 40%;
        height: 20%;
      }
    }
  }
  .form-wrapper {
    padding: 10px 0px;
    .ant-form-inline .ant-form-item {
      margin-left: 16px;
    }
    .submit-button-wrapper {
      width: 100%;
      text-align: center;
      .btn-container {
        padding: 2%;
      }
    }

    .fieldContainer {
      width: 100%;
      .fieldWrapper {
        padding-left: 2%;
      }
    }
  }
}
