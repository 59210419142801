html {
  font-family: system-ui, sans-serif;
  font-size: 1.25rem;
}

body {
  margin: 100px;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.controls {
  display: flex;
  position: fixed;
  top: 85vh;
  left: 68vw;
  text-align: center;
  transform: translateX(-50%);
}

.controls > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 2rem;
  width: 2rem;

  color: #fff;
  border-radius: 100%;
  border: 0;
  background: none;
}

.controls > button:first-child {
  margin-right: 0.25rem;
}

.marker-tooltip-content {
  width: max-content;
  .wrapper {
    overflow: "hidden";
    .list {
      display: flex;

      .list-value {
        margin-left: 2.5px;
        display: flex;
        flex-flow: nowrap;
        overflow: hidden;
      }
    }
    .note-list {
      display: flex;
      .list-value {
        display: flex;
      }
    }
  }
  .title {
    padding: 0.5%;
    display: flex;
    flex-wrap: nowrap;
  }
}
