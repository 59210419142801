.site_assessment {
  width: 100%;

  .content_wrapper {
    padding-right: 0.5%;
    .applied-filters-wrapper {
      margin-bottom: 5px;
    }
    .title_row {
      .radio-buttons-wrapper {
        margin-top: 0.5%;
      }
      .ant-tabs-nav {
        background: #ffffff;
        padding: 0% 1%;
        margin: 0 0 5px;
        height: 35px;
      }

      .searchWrapper {
        padding: 1.5% 0% 0% 1.5%;

        .view-assessment-button {
          font-size: 11px;
        }

        .search-input {
          padding-left: 2%;
          width: 31%;
        }
      }
      .last_updated_text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        color: #000000;
        padding: 0.5% 0% 0% 1.5%;
        font-style: "Mars Centra";
        .alert-refresh-date {
          color: red;
        }
      }
    }
    .page-title {
      font-style: "Mars Centra";
      font-size: 24px;
      font-weight: 700;
      line-height: 33.6px;
      margin: 0% 1% 0.5% 0.1%;
      color: #0000a0 !important;
    }
    .loc-ele-view-radio {
      display: inline-flex;
      margin: 5px;
    }
    .charts-table-switch {
      display: inline-flex;
      background: #b040ffad;
      color: white;
      border-radius: 4px;
      padding: 1px;

      .text {
        font-size: 14px;
        padding: 0 5px;
        font-style: "Mars Centra";
      }

      .switchtotable {
        background-color: #0000a076;
      }
    }

    .main-content {
      background: #fff;

      .tableIndicators {
        padding: 0% 0.5% 0.5% 0.5%;
        margin-right: 5px;

        .percent-compliance {
          font-style: "Mars Centra";
          font-size: 11px;
          line-height: 16px;
          font-weight: 400;
          color: #393939 !important;
          margin-top: 10px;
        }

        .compliance-indicators {
          display: inline-flex;
          margin-left: 5px;

          .compliance-indicator-text {
            font-style: "Mars Centra";
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #393939 !important;
            margin-top: 10px;
          }

          .compliance-indicator-circles {
            border-radius: 50%;
            margin-top: 12px;
            width: 10px;
            height: 10px;
          }

          .info-icon {
            margin-top: 4px;
            width: 12px;
            height: 12px;
            padding-left: 1vw;
            padding-right: 2vw;
          }
        }
      }
    }

    .element-view-content {
      background: #fff;

      .tableIndicators {
        padding: 0% 0.5% 0.5% 0.5%;
        margin-right: 5px;

        .percent-compliance {
          font-style: "Mars Centra";
          font-size: 11px;
          line-height: 16px;
          font-weight: 400;
          color: #393939 !important;
          margin-top: 10px;
        }

        .compliance-indicators {
          display: inline-flex;
          margin-left: 5px;

          .compliance-indicator-text {
            font-style: "Mars Centra";
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #393939;
            margin-top: 10px;
          }

          .compliance-indicator-circles {
            border-radius: 50%;
            margin-top: 12px;
            width: 10px;
            height: 10px;
          }

          .info-icon {
            margin-top: 4px;
            width: 12px;
            height: 12px;
            padding-left: 1vw;
            padding-right: 2vw;
          }
        }
      }
    }

    .site-table-section {
      background: #fff;

      .ant-table {
        font-size: 13px;
      }

      .ant-table-container table > thead > tr {
        border-top-left-radius: 2px;
        padding: 0px;
      }

      .ant-table-container table > thead > tr > th {
        background: #fff;
        font-weight: 200;
      }

      .ant-table.ant-table-small .ant-table-title,
      .ant-table.ant-table-small .ant-table-footer,
      .ant-table.ant-table-small .ant-table-thead > tr > th,
      .ant-table.ant-table-small .ant-table-tbody > tr > td,
      .ant-table.ant-table-small tfoot > tr > th,
      .ant-table.ant-table-small tfoot > tr > td {
        padding: 4px;
      }

      .abs-value {
        font-size: 12px;
      }

      .percent-value {
        font-size: 10px;
      }

      .compliance-percent {
        border-radius: 50%;
        margin-top: 12px;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }

    .barChartWrapper {
      .barChartContainer {
        .barChart {
          background: white;
          height: 30vh;
        }

        .title {
          padding: 0% 2%;
          background: white;
          margin: 0;
        }
      }

      .note-for-charts {
        background-color: #fff;
        margin-bottom: 5px;

        .note-text {
          font-size: 11px;
          color: #8c2dcf;
          margin-left: -475px;
        }
      }
    }

    .radio-selection-level {
      margin: 15 0;
      display: inline-flex;
    }

    .radioGroup {
      margin-bottom: 6px;
    }
  }

  .site-percent-compliance {
    font-size: 10px;
    color: #393939 !important;
  }

  .site-compliance-indicators {
    display: inline-flex;
    margin-left: 5px;
    cursor: pointer;
    .legend-info-btn {
      font-size: 11px;
      font-weight: 600;
      margin-top: 5px;
    }

    .legendInfo {
      .info-container {
        font-size: 14px;
        color: black;
      }

      .info-table {
        width: 30%;
        margin-left: 35%;
      }
    }

    .site-compliance-indicator-text {
      font-style: "Mars Centra";
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      color: #393939;
      margin-top: 10px;
    }

    .text-dark {
      margin-left: 5px;
      color: #393939;
    }

    .text-faded {
      margin-left: 5px;
      color: #d8d8d8;
    }

    .site-compliance-indicator-circles {
      border-radius: 50%;
      margin-top: 12px;
      width: 10px;
      height: 10px;
    }
  }

  .sidebar {
    padding: 0.5% 0.5% 0%;

    .ant-layout-sider-children {
      background-color: #fff;
    }
  }

  .ant-layout-sider {
    background: none !important;
  }

  .red {
    background-color: #df4941;
  }

  .grey {
    background-color: #d8d8d8;
  }

  .orange {
    background-color: #ef981d;
    margin-left: 10px;
  }

  .yellow {
    background-color: #ffcd1c;
    margin-left: 10px;
  }

  .green {
    background-color: #c2da61;
    margin-left: 10px;
  }

  .olive {
    background-color: #6b9962;
    margin-left: 10px;
  }

  .black {
    background-color: #000000;
    margin-left: 10px;
  }

  .left-spaced-grey {
    margin-left: 10px;
    background-color: #d8d8d8;
  }
}

.note-for-table {
  .note-text {
    font-size: 11px;
    color: #8c2dcf;
  }
}

.radio-download-buttons {
  width: 100%;
  .radio-selection-group {
    margin: 1.5% 0.6%;
  }
  .download-button {
    margin: 0.5% 5% 0.5% -1.5%;
  }
}
